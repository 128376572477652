import { driver, type Config } from 'driver.js'
import 'driver.js/dist/driver.css'
import '@styles/onboarding.css'

export const useOnboardingTour = () => {
  const STARTUP_DELAY = 1000 // we need to have the page loaded before starting the tour

  const userStore = useUserStore()

  function launchTour() {
    const { t } = useNuxtApp().$i18n // recommended workaround useNuxtApp().$i18n when const { t } = useI18n() not working in composables

    const steps: Config['steps'] = [
      {
        element: '.nav-header',
        popover: {
          title: t('onboarding.welcome.title'),
          description: t('onboarding.welcome.desc'),
        },
      },

      // browse content

      {
        element: '#main-menu-private-catalog-search',
        popover: {
          title: t('onboarding.search-1.title'),
          description: t('onboarding.search-1.desc'),
        },
      },
      {
        element: '#search-terms',
        popover: {
          title: t('onboarding.search-2.title'),
          description: t('onboarding.search-2.desc'),
        },
      },
      {
        element: '#search-refiners',
        popover: {
          title: t('onboarding.search-3.title'),
          description: t('onboarding.search-3.desc'),
        },
      },
      {
        element: '.dvn-instrument-card',
        popover: {
          title: t('onboarding.result-1.title'),
          description: t('onboarding.result-1.desc'),
        },
      },
      {
        element: '.dvn-item-cover',
        popover: {
          title: t('onboarding.result-2.title'),
          description: t('onboarding.result-2.desc'),
        },
      },
      {
        element: '#search-instrument-details',
        popover: {
          title: t('onboarding.result-3.title'),
          description: t('onboarding.result-3.desc'),
        },
      },
      {
        element: '#search-instrument-buttons',
        popover: {
          title: t('onboarding.result-4.title'),
          description: t('onboarding.result-4.desc'),
        },
      },
      {
        element: '.v-slide-group',
        popover: {
          title: t('onboarding.videos.title'),
          description: t('onboarding.videos.desc'),
        },
      },
      {
        element: '#instrument-download-link',
        popover: {
          title: t('onboarding.download.title'),
          description: t('onboarding.download.desc'),
        },
      },

      // favorites
      {
        element: '#main-menu-private-user-favorites',
        popover: {
          title: t('onboarding.favorites.title'),
          // description: t('onboarding.favorites.desc'),
        },
      },

      // purchases
      {
        element: '#main-menu-private-user-purchases',
        popover: {
          title: t('onboarding.purchases.title'),
          // description: t('onboarding.purchases.desc'),
        },
      },

      // add content
      {
        element: '#main-menu-private-catalog-packs-list',
        popover: {
          title: t('onboarding.packs.title'),
          description: t('onboarding.packs.desc'),
        },
      },
      {
        element: '#main-menu-private-catalog-instruments-list',
        popover: {
          title: t('onboarding.instruments.title'),
          description: t('onboarding.instruments.desc'),
        },
      },

      //  User

      {
        element: '#user-menu',
        popover: {
          title: t('onboarding.user-menu.title'),
          description: t('onboarding.user-menu.desc'),
        },
      },

      // DiViNe Desktop

      {
        element: '#user-menu',
        popover: {
          title: t('onboarding.desktop.title'),
          description: `${t(
            'onboarding.desktop.desc.1',
          )} <a href='/site/desktop-download' target='_blank'>DiViNe Desktop</a> ${t(
            'onboarding.desktop.desc.2',
          )}`,
        },
      },
    ]

    const driverObj = driver({
      steps,
      animate: true,
      allowClose: true,
      showButtons: ['next', 'previous', 'close'],
      showProgress: true,
      nextBtnText: t('onboarding.next') + ' →',
      prevBtnText: '← ' + t('onboarding.prev'),
      doneBtnText: t('onboarding.done'),
      progressText: `{{current}} ${t('onboarding.of')} {{total}}`,
      popoverClass: 'onboarding-theme',

      onCloseClick: () => {
        driverObj.destroy()
      },

      onDestroyStarted: async () => {
        // once completed, disable onboarding
        if (driverObj.isLastStep()) {
          await userStore.updateOnboarded(true)
          driverObj.destroy()
        }
      },

      // onDestroyed: () => {},
    })
    driverObj.drive()
  }

  function start() {
    // if (userStore.user?.role === 'admin') return
    if (userStore.user?.onboarded !== true) {
      setTimeout(() => {
        launchTour()
      }, STARTUP_DELAY)
    }
  }

  return {
    start,
  }
}
